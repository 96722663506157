import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./page.css";
import { NodeServer_API } from "../server";
import axios from "axios";
import { Store } from "../Redux/store";
import { priviledgeData, rolesData } from "../Redux/Actions/roles";
import { loadingData } from "../Redux/Actions/buttonClick";
import {
  getAllAgencies,
  getAllAgenciespcc,
  getAllProviders,
  getPaymentOptions,
} from "../Redux/Actions/dataResponse";
import { useCookies } from "react-cookie";
import Privileges from "../Component/Privileges";
import Users from "../Component/Users";
import Roles from "../Component/Roles";
import Payments from "../Component/Payments";
import Paymentprovider from "../Component/Paymentprovider";
import Support from "../Component/Support";
import RuleEngine from "../Component/Ruleengine";
import Blacklist from "../Component/Blacklist";

const getFirstMatchingTabId = (role, privileges, tabs) => {
  // Find the first tab that matches both role and privileges
  const matchingTab = tabs.find(
    (tab) =>
      tab.rolesAllowed.includes(role) &&
      tab.requiredPrivilege.every((priv) => privileges.includes(priv))
  );

  // Return the id of the matching tab, or null if none found
  return matchingTab ? matchingTab.id : null;
};

const Settings = ({ setloading }) => {
  const [activeTab, setActiveTab] = useState(null);
  const [cookies] = useCookies(["Token"]);

  let { role } = useSelector((state) => state.datareponse); // Get role from Redux store
  let { privileges } = useSelector((state) => state.rolesData); // Get privileges from Redux store
  const dispatch = useDispatch();

  privileges = privileges.map((item) => item.privilege_name); // Normalize privileges

  loadingData({
    status: false,
    text: "",
    type: "spin",
  });

  useEffect(() => {
    FetchRoles();
    FetchPriviledges();
    FetchAgencies();
    FetchAgenciespcc();
    FetchPaymentProviders();
    FetchPaymentOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FetchRoles = async () => {
    try {
      const config = {
        method: "GET",
        url: `${NodeServer_API}/roles`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(rolesData(response.data.data));
        }
      });
    } catch (error) {
      if (error.response.status === 401) {
        setloading({
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      }
    }
  };

  const FetchPriviledges = async () => {
    try {
      const config = {
        method: "GET",
        url: `${NodeServer_API}/privileges`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(priviledgeData(response.data.data));
        }
      });
    } catch (error) {
      if (error.response.status === 401) {
        setloading({
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      }
    }
  };

  const FetchAgencies = async () => {
    try {
      const config = {
        method: "GET",
        url: `${NodeServer_API}/agents/list`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(getAllAgencies(response.data.data));
        }
      });
    } catch (error) {
      if (error.response.status === 401) {
        setloading({
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      }
    }
  };

  const FetchPaymentOptions = async () => {
    try {
      const config = {
        method: "GET",
        url: `${NodeServer_API}/payment`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(getPaymentOptions(response.data.data));
        }
      });
    } catch (error) {
      if (error.response.status === 401) {
        setloading({
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      }
    }
  };

  const FetchPaymentProviders = async () => {
    try {
      const config = {
        method: "GET",
        url: `${NodeServer_API}/payment/provider`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(getAllProviders(response.data.data));
        }
      });
    } catch (error) {
      if (error.response.status === 401) {
        setloading({
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      }
    }
  };

  const FetchAgenciespcc = async () => {
    try {
      const config = {
        method: "GET",
        url: `${NodeServer_API}/agents/list/agencies`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(getAllAgenciespcc(response.data.data));
        }
      });
    } catch (error) {
      if (error.response.status === 401) {
        setloading({
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      }
    }
  };

  const tabs = [
    {
      id: 1,
      label: "Previledges",
      rolesAllowed: ["DEVELOPER", "SUPERADMIN"],
      requiredPrivilege: ["CREATE_PRIVILEGES"],
    },
    {
      id: 2,
      label: "Roles",
      rolesAllowed: ["DEVELOPER", "SUPERADMIN"],
      requiredPrivilege: ["CREATE_PRIVILEGES", "CREATE_PRIVILEGES"],
    },
    {
      id: 3,
      label: "Users",
      rolesAllowed: ["DEVELOPER", "ADMIN", "SUPERADMIN", "MANAGER"],
      requiredPrivilege: ["CREATE_PRIVILEGES", "CREATE_PRIVILEGES"],
    },
    {
      id: 4,
      label: "Payments Providers",
      rolesAllowed: ["DEVELOPER", "ADMIN", "ACCOUNTANT"],
      requiredPrivilege: ["CREATE_PRIVILEGES", "CREATE_PRIVILEGES"],
    },
    {
      id: 5,
      label: "Payment Options",
      rolesAllowed: ["DEVELOPER", "ADMIN", "ACCOUNTANT"],
      requiredPrivilege: ["CREATE_PRIVILEGES"],
    },
    {
      id: 6,
      label: "Rule Engine",
      rolesAllowed: ["DEVELOPER", "ADMIN", "USER"],
      requiredPrivilege: ["CREATE_PRIVILEGES", "CREATE_PRIVILEGES"],
    },
    {
      id: 7,
      label: "Security",
      rolesAllowed: ["DEVELOPER"],
      requiredPrivilege: ["CREATE_PRIVILEGES", "CREATE_PRIVILEGES"],
    },
    {
      id: 8,
      label: "Support",
      rolesAllowed: ["DEVELOPER"],
      requiredPrivilege: ["CREATE_PRIVILEGES"],
    },
    {
      id: 9,
      label: "Blacklist",
      rolesAllowed: ["DEVELOPER", "ADMIN", "USERS"],
      requiredPrivilege: ["CREATE_PRIVILEGES", "ADD_BLACKLIST"],
    },
  ];

  const firstMatchingId = getFirstMatchingTabId(role, privileges, tabs);

  // Filter tabs based on role and privileges
  const filteredTabs = tabs.filter((tab) => {
    const hasRequiredRole = tab.rolesAllowed.includes(role);
    const hasRequiredPrivilege = tab.requiredPrivilege.every((priv) =>
      privileges.includes(priv)
    );
    return hasRequiredRole && hasRequiredPrivilege;
  });

  // Set the first available tab on initial render only
  useEffect(() => {
    if (!activeTab) {
      if (firstMatchingId) {
        setActiveTab(firstMatchingId); // Automatically select the first matching tab
      } else if (filteredTabs.length > 0) {
        setActiveTab(filteredTabs[0].id); // Set the first tab if no matching tab is found
      } else {
        setActiveTab(1); // Default tab if no match or tabs
      }
    }
  }, [firstMatchingId, filteredTabs, activeTab]);

  // Handle tab click
  const handleTabClick = (id) => {
    setActiveTab(id); // Set the clicked tab as active
    dispatch({ type: "TAB_CLICK", payload: id }); // Dispatch action if necessary
  };

  return (
    <div className="w-full h-screen flex relative">
      <div className="w-[180px] h-fit py-2 px-1 flex flex-col bg-[#f0f0f0] shadow-lg sticky top-0">
        {filteredTabs.map((tab) => (
          <div
            key={tab.id}
            className={`text-[11px] p-2 flex items-center justify-start pl-6 w-full cursor-pointer ${
              activeTab === tab.id
                ? "bg-[#a4a4a4] text-[white]"
                : "bg-[white] text-[#333]"
            }`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="flex-1 p-2 w-full">
        {activeTab === 1 ? (
          <Privileges />
        ) : activeTab === 2 ? (
          <Roles />
        ) : activeTab === 3 ? (
          <Users />
        ) : activeTab === 4 ? (
          <Paymentprovider />
        ) : activeTab === 5 ? (
          <Payments />
        ) : activeTab === 6 ? (
          <RuleEngine />
        ) : activeTab === 8 ? (
          <Support />
        ) : activeTab === 9 ? (
          <Blacklist />
        ) : (
          <h2 className="text-xl font-bold">
            Settings Content for{" "}
            {filteredTabs.find((tab) => tab.id === activeTab)?.label}
          </h2>
        )}
      </div>
    </div>
  );
};

export default Settings;
