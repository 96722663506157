import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import axios from "axios";
import PasswordInputField from "./Passwordverify/password";
import ConfirmPasswordInputField from "./Passwordverify/Confirmpassword";
import { NodeServer_API } from "../server";
import { toast } from "react-toastify";
import { Store } from "../Redux/store";
import { getAllDirectors } from "../Redux/Actions/dataResponse";
import { loadingData } from "../Redux/Actions/buttonClick";

const Users = () => {
  const [selectedUser, setSelectedUser] = useState({
    firstname: "",
    lastname: "",
    email_address: "",
    password: "",
    confirmPassword: "",
  });

  loadingData({
    status: false,
    text: "",
    type: "spin",
  });

  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  // const [selectedPrivileges, setSelectedPrivileges] = useState([]);

  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  // const users = useSelector((state) => state.users);
  const { rolelist, privileges } = useSelector((state) => state.rolesData);

  const { regionlist } = useSelector((state) => state.datareponse);

  const dispatch = useDispatch();

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleRoleChange = (role) => {
    setSelectedRole(role);
  };

  const handleRegionChange = (region) => {
    setSelectedRegion(region);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    Store.dispatch(
      loadingData({
        status: true,
        text: "Creating Users...",
        type: "spin",
      })
    );

    // Construct the payload
    const payload = {
      ...selectedUser,
      role: selectedRole?.label,
      role_id: selectedRole?.value,
      region_code: selectedRegion?.regionCode,
    };

    try {
      // Send POST request to update the user
      const response = await axios.post(`${NodeServer_API}/directors`, payload);

      if (response.data.success === true) {
        Store.dispatch(
          loadingData({
            status: false,
            text: "",
            type: "spin",
          })
        );

        // console.log("Successfully saved:", response.data);
        toast.success(response.data.message);
        Store.dispatch(getAllDirectors(response.data.data));
      }
    } catch (error) {
      console.error("Error saving user roles and privileges:", error);

      // Optionally dispatch an action to handle the error
      dispatch({
        type: "UPDATE_USER_FAILURE",
        error,
      });
    }
  };

  const roleOptions = rolelist?.map((role) => ({
    value: role.id,
    label: role.role_name,
  }));

  // regionsjson
  const regionOptions = regionlist?.map((region) => ({
    value: region.ID,
    label: region.name,
    regionCode: region.region_code,
  }));

  const privilegeOptions = privileges?.map((privilege) => ({
    value: privilege.id,
    label: privilege.privilege_name,
  }));

  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {
      ...selectedUser,
      [passwordInputFieldName]: passwordInputValue,
    };
    setSelectedUser(NewPasswordInput);
    // // console.log(`setPass: ${JSON.stringify(NewPasswordInput)}`)
  };

  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = ""; // Password is empty
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }

    // for confirm password
    if (
      passwordInputFieldName === "confirmPassword" ||
      (passwordInputFieldName === "password" &&
        selectedUser.confirmPassword.length > 0)
    ) {
      if (selectedUser.confirmPassword !== selectedUser.password) {
        setConfirmPasswordError("Confirm password is not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  return (
    <form
      onSubmit={handleSave}
      className="flex-1 bg-[white] p-4 flex flex-col gap-3"
    >
      <div className="flex justify-center items-center">
        <h2 className="text-l font-bold mb-2">CREATE USERS</h2>
      </div>

      <div className="flex-1 flex-wrap flex gap-2 items-center justify-between">
        <input
          name="firstname"
          onChange={handleUserChange}
          className="text-[12px] border border-gray-300 py-2 px-3 w-[30%] min-w-[180px] outline-none hover:border-gray-500 focus:border-blue-500 rounded-sm grow"
          value={selectedUser.firstname}
          type="text"
          required
          placeholder="Firstname"
        />

        <input
          name="lastname"
          onChange={handleUserChange}
          className="text-[12px] border border-gray-300 py-2 px-3 w-[30%] min-w-[180px] outline-none hover:border-gray-500 focus:border-blue-500 rounded-sm grow"
          value={selectedUser.lastname}
          type="text"
          required
          placeholder="Lastname"
        />
      </div>

      <div className="flex-1 flex-wrap flex gap-2 items-center justify-between">
        <input
          name="email_address"
          onChange={handleUserChange}
          className="text-[12px] border border-gray-300 py-2 px-3 w-[30%] min-w-[180px] outline-none hover:border-gray-500 focus:border-blue-500 rounded-sm grow"
          value={selectedUser.email_address}
          type="email"
          required
          placeholder="Email Address"
        />
      </div>

      <div className="flex-1 flex-wrap flex gap-2 items-start justify-between">
        <PasswordInputField
          handlePasswordChange={handlePasswordChange}
          handleValidation={handleValidation}
          passwordValue={selectedUser.password}
          passwordError={passwordError}
        />

        <ConfirmPasswordInputField
          handlePasswordChange={handlePasswordChange}
          handleValidation={handleValidation}
          confirmPasswordValue={selectedUser.confirmPassword}
          confirmPasswordError={confirmPasswordError}
        />
      </div>

      <div className="flex-1 flex-wrap flex gap-2 items-start justify-between">
        <Select
          options={roleOptions}
          value={selectedRole}
          onChange={handleRoleChange}
          className="w-[30%] min-w-[180px] text-[12px] rounded-sm grow"
          placeholder="Select Role"
        />

        <Select
          options={regionOptions}
          value={selectedRegion}
          onChange={handleRegionChange}
          className="w-[30%] min-w-[180px] text-[12px] rounded-sm grow"
          placeholder="Select Region"
        />
      </div>

      <input
        type="submit"
        className="btn btn-primary mt-4 text-[14px] font-semibold text-[white] py-2 rounded-sm bg-[#0743077d] hover:bg-[#074307f7] cursor-pointer"
        value={"SAVE CHANGES"}
      />
    </form>
  );
};

export default Users;
