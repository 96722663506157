import React, { useState, useEffect } from "react";
import ButtonPagenate from "./ButtonPagenate/ButtonPagenate";
import Select from "react-select";
import { useSelector } from "react-redux";
import { NodeServer_API } from "../server";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Store } from "../Redux/store";
import { loadingData } from "../Redux/Actions/buttonClick";
import { toast } from "react-toastify";
import { blacklistedData } from "../Redux/Actions/roles";
import { AiFillDelete } from "react-icons/ai";
import { CgCloseO } from "react-icons/cg";
import { airlines } from "../utils/airlinecodes";

const RuleEngine = ({ setloading }) => {
  const [cookies] = useCookies(["Token"]);
  const [confirm, setConfirm] = useState(false);
  const [rowid, setRowid] = useState(0);
  const { blacklist } = useSelector((state) => state.rolesData);
  const { agency } = useSelector((state) => state.datareponse);
  const [selectedOption, setSelectedOption] = useState("airlines"); // Default to airlines
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedlabel, setSelectedlabel] = useState("");
  const [pageNumber, setPageNumber] = useState(0);

  const { agencylist } = useSelector((state) => state.datareponse);

  const newPagenum = (num) => {
    setPageNumber(num);
  };

  useEffect(() => {
    fetchBlacklist();
  }, []);

  const itemsPerpage = 20; //Number of items to be displayed per page
  const pagesVisited = pageNumber * itemsPerpage;
  const pageCount = Math.ceil(
    (blacklist === null ? 0 : blacklist.length) / itemsPerpage
  );

  // Handle change in radio buttons
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedValue(""); // Reset the dropdown value on option change
  };

  // Handle change in select dropdown
  const handleSelectChange = (props) => {
    setSelectedValue(props.value);
    setSelectedlabel(props.label);
  };

  // Handle form submission
  const handleSubmitForm = async (event) => {
    event.preventDefault();
    const payload = {
      parameter:
        selectedOption === "airlines"
          ? "AIRLINE"
          : selectedOption === "agencies"
          ? "AGENCY"
          : "AGENT",
      pcc_airline: selectedValue,
    };

    if (payload.pcc_airline !== "") {
      Store.dispatch(
        loadingData({
          status: true,
          text: `Adding ${selectedlabel} to Blacklist...`,
          type: "spin",
        })
      );

      // You can replace this with an API call, e.g., using fetch or axios

      try {
        var config = {
          method: "POST",
          url: `${NodeServer_API}/blacklist`,
          data: payload,
          headers: {
            Authorization: "Bearer " + cookies.Token,
          },
        };

        await axios(config).then((response) => {
          // console.log(response.data);
          Store.dispatch(blacklistedData(response.data.data));
          toast.success(response.data.message);
          Store.dispatch(
            loadingData({
              status: false,
              text: "",
              type: "spin",
            })
          );
        });
      } catch (error) {
        // console.log(error);
        toast.error(error.response.data.message);
        Store.dispatch(
          loadingData({
            status: false,
            text: "",
            type: "spin",
          })
        );

        // Handle error
        if (error.response.status === 401) {
          setloading({
            // Expired Session
            statuscode: 3,
            message: "Session Expired Re-Login",
            type: 3,
          });
        }
      }
    } else {
      toast.warning("Ensure required field is filled");
    }
  };

  const handleConfirm = (props) => {
    setConfirm(true);
    setRowid(props);
  };

  const fetchBlacklist = async () => {
    try {
      const config = {
        method: "GET",
        url: `${NodeServer_API}/blacklist`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(blacklistedData(response.data.data));
        }
      });
    } catch (error) {
      if (error.response.status === 401) {
        setloading({
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      }
    }
  };

  // Open performance appraisal page to fetch the record of user with the particular ID if it exists in the db
  const handleDelete = async () => {
    setConfirm(false);

    Store.dispatch(
      loadingData({
        status: true,
        text: "Removing Blacklist...",
        type: "spin",
      })
    );

    try {
      var config = {
        method: "DELETE",
        url: `${NodeServer_API}/blacklist/${rowid}`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        // console.log(response.data.data);
        if (response.data.success === true) {
          toast.success(response.data.message);
          Store.dispatch(blacklistedData(response.data.data));

          Store.dispatch(
            loadingData({
              status: false,
              text: "",
              type: "spin",
            })
          );
        }
      });
    } catch (e) {
      Store.dispatch(
        loadingData({
          status: false,
          text: "",
          type: "spin",
        })
      );

      if (e.response.status === 401) {
        setloading({
          statuscode: 3,
          message: "Session Expired Re-Login",
          type: 3,
        });
      }

      toast.error(e.response.data.message);
    }
  };

  const closeConfirm = () => {
    setConfirm(false);
  };

  return (
    <div className="flex-1 flex-col flex gap-3 relative">
      {confirm && (
        <div className="bg-[#00000042] w-full h-screen fixed right-0 z-10 top-0 flex justify-center items-center">
          <div className="w-[50%] min-w-[280px] h-[250px] bg-[white] p-3 flex items-center flex-col gap-3">
            <div className="w-full flex items-center justify-end">
              <CgCloseO
                onClick={closeConfirm}
                size={28}
                className="cursor-pointer"
              />
            </div>

            <span className="text-[15px] font-[800] underline underline-offset-4">
              REMOVE RECORD!
            </span>

            <span className="text-[14px] text-center">
              Are you sure you want to remove this blacklisted record?
            </span>

            <div className="flex justify-around items-center w-full mt-5">
              <div className="relative bg-[blue] h-fit flex items-center justify-center">
                <button
                  onClick={handleDelete}
                  className="bg-[green] text-[white] flex justify-center items-center text-[12px] font-[500] w-[50px] p-2 cursor-pointer"
                >
                  YES
                </button>
              </div>

              <div className="relative h-fit flex items-center justify-center">
                <button
                  onClick={closeConfirm}
                  className="bg-[red] text-[white] flex justify-center items-center text-[12px] font-[500] w-[50px] p-2 cursor-pointer"
                >
                  NO
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <form
        className="flex-1 bg-[white] p-4 flex flex-col gap-3 w-full"
        onSubmit={handleSubmitForm}
      >
        <div className="flex justify-center items-center">
          <h2 className="text-l font-bold mb-2">CREATE BLACKLIST</h2>
        </div>

        {/* Radio buttons */}
        <div className="w-full items-center justify-start gap-9 flex">
          <label className="flex gap-3 text-[12px]">
            <input
              type="radio"
              value="airlines"
              checked={selectedOption === "airlines"}
              onChange={handleOptionChange}
              className="cursor-pointer"
            />
            Airline
          </label>

          <label className="flex gap-3 text-[12px]">
            <input
              type="radio"
              value="agent"
              checked={selectedOption === "agent"}
              onChange={handleOptionChange}
              className="cursor-pointer"
            />
            Agent
          </label>

          <label className="flex gap-3 text-[12px]">
            <input
              type="radio"
              value="agencies"
              checked={selectedOption === "agencies"}
              onChange={handleOptionChange}
              className="cursor-pointer"
            />
            Agencies
          </label>
        </div>

        {/* Dropdown selection based on selected option */}
        <div className="w-full items-center justify-start gap-9 flex">
          {selectedOption === "airlines" ? (
            <div className="flex w-full gap-1 flex-col justify-start">
              <label className="text-[12px] font-medium">
                Select an Airline:
              </label>
              <Select
                id="airline"
                options={airlines}
                defaultValue={selectedValue}
                onChange={handleSelectChange}
                className="w-full min-w-[180px] text-[12px] rounded-sm grow"
                placeholder="Select Airline"
              />
            </div>
          ) : selectedOption === "agent" ? (
            <div className="flex w-full gap-1 flex-col justify-start">
              <label className="text-[12px] font-medium">
                Select an Agent:
              </label>
              <Select
                id="agency"
                options={agency.map((agent) => ({
                  value: agent?.promo_code,
                  label: `${agent.agency_name} ➡️ ${agent.first_name} ${agent.last_name}`,
                }))}
                defaultValue={selectedValue}
                onChange={handleSelectChange}
                className="w-full min-w-[180px] text-[12px] rounded-sm grow"
                placeholder="Select an Agent"
              />
            </div>
          ) : (
            <div className="flex w-full gap-1 flex-col justify-start">
              <label className="text-[12px] font-medium">
                Select an Agency:
              </label>
              <Select
                id="agency"
                options={agencylist}
                defaultValue={selectedValue}
                onChange={handleSelectChange}
                className="w-full min-w-[180px] text-[12px] rounded-sm grow"
                placeholder="Select an Agency"
              />
            </div>
          )}
        </div>

        <input
          type="submit"
          className="btn btn-primary mt-4 text-[14px] font-semibold text-[white] py-2 rounded-sm bg-[#0743077d] hover:bg-[#074307f7] cursor-pointer"
          value={"SAVE CHANGES"}
        />
      </form>

      <div className="flex-1 bg-[white] p-3 flex flex-col gap-2">
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                #SN
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Metrics
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Blacklist
              </th>
              <th className="text-[11px] whitespace-normal break-words border px-2 py-1">
                Delete
              </th>
            </tr>
          </thead>

          <tbody>
            {blacklist
              .slice(pagesVisited, pagesVisited + itemsPerpage)
              .map((tdata, index) => (
                <tr className="body-tab-row cursor-pointer" key={tdata.id}>
                  <td
                    data-label="SN"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {index + 1}
                  </td>

                  <td
                    data-label="Metrics"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {tdata.parameter}
                  </td>
                  <td
                    data-label="Blacklist"
                    className="text-[11px] whitespace-normal break-words border px-2 py-1"
                  >
                    {tdata.pcc_airline}
                  </td>
                  <td
                    data-label="Action"
                    className="text-[11px] flex items-center justify-center whitespace-normal break-words border px-2 py-1"
                  >
                    <AiFillDelete
                      onClick={() => handleConfirm(tdata.id)}
                      id="butn-action"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div className="bnw-table-pgnate-contain">
          <ButtonPagenate
            updatedPageNum={newPagenum}
            pageCount={pageCount}
            pageNumber={pageNumber}
            previousLabel="Prev"
            nextLabel="Next"
          />
        </div>
      </div>
    </div>
  );
};

export default RuleEngine;
