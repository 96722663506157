import { createAction, createReducer } from "@reduxjs/toolkit";
const AvailableRoles = createAction("AvailableRoles");
const AvailablePriviledge = createAction("AvailablePriviledge");
const Blacklist = createAction("Blacklist");

const initialState = {
  rolelist: [],
  privileges: [],
  blacklist: [],
};

export const roleprevilegeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(AvailableRoles, (state, action) => {
      state.rolelist = action.payload;
    })

    .addCase(AvailablePriviledge, (state, action) => {
      state.privileges = action.payload;
    })

    .addCase(Blacklist, (state, action) => {
      state.blacklist = action.payload;
    });
});
