import React, { useState, useEffect } from "react";
import "./page.css";
import SLoader from "../Component/Loading/SigninLoader";
// import {useLocation} from 'react-router-dom';
import Sidebar from "../Component/Sidebar/Sidebar";
import TopSidebar from "../Component/Sidebar/TopSidebar";
import Body from "./body";
import Claims from "./Claims/claims";
import Director from "./director";
import Agencies from "./agencies";
import Settings from "./settings";
import Confirmpg from "../Component/Confirmpg";
import AddDirectors from "../Component/Cards/AddDirectors";
import axios from "axios";
import { useCookies } from "react-cookie";
import Error from "../Component/Error";
import Promo from "./Promo";
import Regions from "./Regions";
import Loginpg from "./Login";
import Confirms from "../Component/Confirms";
import AddRegions from "../Component/Cards/AddRegions";
import Exchangerate from "./Exchangerate";
import LoadVeriy from "../Component/Loading/LoadVeriy";
import Allocate from "../Component/Allocate/allocate";
import AddPromo from "../Component/Cards/AddPromo";
import Wallet from "./wallet";
import Budget from "./budget";
import Claim from "./Claims/claim";
import { SeerbitDataID } from "../data/data";
import { Store, resetStore } from "../Redux/store";
import {
  claimsData,
  financialData,
  getActiveClaims,
  getAgenciesCount,
  getAllDirectors,
  getAllExchangerate,
  getAllRegions,
  getAllocation,
  getApprovedClaims,
  getCanceledClaims,
  getName,
  getRole,
  homepageData,
  marketallocationData,
  paystackRecipientList,
  paystackTotalDisbursed,
  paystackTransactionHistory,
  paystackWalletBalance,
  pointBreakdown,
  pointsData,
  seerbitPocketData,
  transactionhistoryData,
} from "../Redux/Actions/dataResponse";
import { pocketDetails } from "../Redux/Actions/pocketDetails";
import { useSelector } from "react-redux";
import { NodeServer_API, Paystack_url } from "../server";
import Budgetfinance from "../Component/Allocate/budgetfinance";
import { getCurrentDate } from "../utils/currentdate";
import { getMonthNumber } from "../Component/DateSelector/Monthselector";
import { rolesData } from "../Redux/Actions/roles";
import Loader from "../Component/Loading/Loading";
import Transaction from "./Transaction";
import Subpockets from "./Subpockets";
import Pockets from "../Component/Pockets/createPocket";
import { jwtDecode } from "jwt-decode";
import { boxloadingstatus } from "../Redux/Actions/buttonClick";

const Dashboard = ({ setloading }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["Token"]);

  // // Frontend function to refresh token
  // const refreshToken = async () => {
  //   try {
  //     const config = {
  //       method: "POST",
  //       url: `${NodeServer_API}/login/refresh-token`,
  //       headers: {
  //         Authorization: "Bearer " + cookies.Token,
  //       },
  //     };

  //     await axios(config).then((response) => {
  //       if (response.data.code === 200) {
  //         setCookie("Token", response.data.token, { path: "/" });
  //       }
  //     });
  //   } catch (error) {
  //     console.error("Failed to refresh token", error);
  //   }
  // };

  // // Detect user activity and reset token
  // let inactivityTimeout;
  // const resetInactivityTimeout = () => {
  //   clearTimeout(inactivityTimeout); // Clear the previous timeout

  //   inactivityTimeout = setTimeout(() => {
  //     console.log("No activity detected for a while. Refreshing token...");
  //     refreshToken(); // Refresh token or any other action
  //   }, 5 * 60 * 1000); // Wait for 5 minuites before Refreshing token due to inactivity
  // };

  // // Attach event listeners to detect activity
  // useEffect(() => {
  //   window.addEventListener("mousemove", resetInactivityTimeout);
  //   window.addEventListener("keydown", resetInactivityTimeout);

  //   console.log("Event listeners attached");

  //   // Cleanup event listeners on component unmount
  //   return () => {
  //     window.removeEventListener("mousemove", resetInactivityTimeout);
  //     window.removeEventListener("keydown", resetInactivityTimeout);
  //   };
  // }, []);

  // // Logout due to inactivity (frontend)
  // const logoutDueToInactivity = () => {
  //   removeCookie("Token", { path: "/" }); // Remove the token cookie
  //   window.location.href = "/"; // Redirect to login page
  //   alert("You have been logged out due to inactivity.");
  // };

  // // Set inactivity timer for session timeout
  // let sessionTimeout;
  // const setSessionTimeout = () => {
  //   clearTimeout(sessionTimeout);
  //   sessionTimeout = setTimeout(logoutDueToInactivity, 8 * 60 * 1000); // 15 minutes of inactivity
  // };

  // // Attach event listeners to reset session timeout
  // useEffect(() => {
  //   window.addEventListener("mousemove", setSessionTimeout);
  //   window.addEventListener("keydown", setSessionTimeout);

  //   // Initialize timeout on page load
  //   setSessionTimeout();

  //   // Cleanup event listeners on component unmount
  //   return () => {
  //     clearTimeout(sessionTimeout);
  //     window.removeEventListener("mousemove", setSessionTimeout);
  //     window.removeEventListener("keydown", setSessionTimeout);
  //   };
  // }, []);

  // Decode the token to extract the payload (the data inside the token)
  const decodedToken = jwtDecode(cookies.Token);

  // Access the role and username from the decoded payload
  const role = decodedToken.role;
  const firstname = decodedToken.firstname;
  const lastname = decodedToken.lastname;

  // console.log(firstname);
  // console.log(lastname);

  Store.dispatch(getRole(role));
  Store.dispatch(getName(lastname + " " + Array.from(firstname)[0] + "."));

  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState(true);
  const [canlogin, setCanlogin] = useState(false);
  const [addDirector, setAddDirector] = useState(false);
  const [expiration, setExpiration] = useState(false);
  const [addRegion, setAddRegion] = useState(false);
  const [addProm, setAddProm] = useState(false);
  const [treatemail, SetTreatemail] = useState();
  const [agencyName, setAgencyName] = useState("");
  const [adminregion, setAdminregion] = useState("");

  const page = useSelector((state) => state.buttonclick.buttonClicks);

  const notice = useSelector((state) => state.buttonclick.newmodal);
  const { selectedmonth, treatConfirm, selectedyear, isLoading, toggleStatus } =
    useSelector((state) => state.buttonclick);

  let datefrom = `${selectedyear}-${getMonthNumber(selectedmonth)}-01`;
  let dateto = getCurrentDate();

  const [successful, setSuccessful] = useState({
    statuscode: 0,
    message: "",
    type: "",
  });

  //user1, admin
  useEffect(() => {
    Makerolecall();
    FetchAllClaims();
    FetchTransactionHistroy();
    FetchAllAllocation();
    FetchAllPoints();
    FetchPaystackBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    FetchDirectors();
    FetchamountDue();
    Makeseerbitcall();
    FetchPointBreakdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    FetchPaystackTotalDisbursed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datefrom]);

  useEffect(() => {
    FetchprimaryPockets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    FetchPaystackTransactionHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateto]);

  useEffect(() => {
    PaystackListRecipient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FetchprimaryPockets = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/seerbit/fetchprimarypockets`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(seerbitPocketData(response.data.data));
        }
      });
    } catch (e) {
      // toast.error(e.response.data);
    }
  };

  const PaystackListRecipient = async () => {
    try {
      var config = {
        method: "GET",
        url: `${Paystack_url}/transferrecipient`,
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_PAYSTACK_SECRET_KEY,
        },
      };

      await axios(config).then((response) => {
        if (response.data.status === true) {
          Store.dispatch(paystackRecipientList(response.data.data));
        }
      });
    } catch (e) {
      // toast.error(e.response.data);
    }
  };

  const FetchAllClaims = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/claims`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(claimsData(response.data.data));
        }
      });
    } catch (e) {
      // console.log(e);
    }
  };

  const FetchTransactionHistroy = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/transactionHistory`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(transactionhistoryData(response.data.data));
        }
      });
    } catch (e) {
      // console.log("error: ", e.response);
    }
  };

  const FetchAllAllocation = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/finance`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(marketallocationData(response.data.data));
        }
      });
    } catch (e) {
      // // console.log("error: ", e.response);
    }
  };

  const FetchAllPoints = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/points`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        Store.dispatch(boxloadingstatus(false));
        if (response.data.success === true) {
          Store.dispatch(pointsData(response.data.data));
        }
      });
    } catch (e) {
      // console.log("error: ", e.response);
    }
  };

  const FetchPaystackBalance = async () => {
    resetStore();

    try {
      var config = {
        method: "GET",
        url: `${Paystack_url}/balance`,
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_PAYSTACK_SECRET_KEY,
        },
      };

      await axios(config).then((response) => {
        if (response.data.status === true) {
          Store.dispatch(paystackWalletBalance(response.data.data[0]?.balance));
        }
      });
    } catch (e) {
      // console.log("error: ", e.response);
    }
  };

  const FetchPaystackTotalDisbursed = async () => {
    try {
      var config = {
        method: "GET",
        url: `${Paystack_url}/transaction/totals?from=2024-03-31&to=${dateto}`,
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_PAYSTACK_SECRET_KEY,
        },
      };

      await axios(config).then((response) => {
        if (response.data.status === true) {
          Store.dispatch(
            paystackTotalDisbursed(response.data.data.total_volume)
          );
        }
      });
    } catch (e) {
      // console.log("error: ", e.response);
    }
  };

  const FetchPaystackTransactionHistory = async () => {
    try {
      var config = {
        method: "GET",
        url: `${Paystack_url}/transaction?perPage=500&from=2016-02-27T16:12:40.220Z&to=${dateto}`,
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_PAYSTACK_SECRET_KEY,
        },
      };

      await axios(config).then((response) => {
        if (response.data.status === true) {
          Store.dispatch(paystackTransactionHistory(response.data.data));
        }
      });
    } catch (e) {
      // // console.log("error: ", e.response);
    }
  };

  const Makerolecall = async () => {
    resetStore();

    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/homepage`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          setAdminregion(response.data.data.region_code);
          Store.dispatch(homepageData(response.data.data));
          Store.dispatch(getAgenciesCount(response.data.data.agents));
          Store.dispatch(getActiveClaims(response.data.data.active_claims));
          Store.dispatch(getApprovedClaims(response.data.data.approved_claims));
          Store.dispatch(
            getCanceledClaims(response.data.data.cancelled_claims)
          );
          Store.dispatch(getAllRegions(response.data.data.regions));
          Store.dispatch(getAllExchangerate(response.data.data.exchange_rate));
          // Store.dispatch(getRole(response.data.data.role));
          // Store.dispatch(
          //   getName(
          //     response.data.data.directors_last_name +
          //       " " +
          //       Array.from(response.data.data.directors_first_name)[0] +
          //       "."
          //   )
          // );
          Store.dispatch(
            getAllocation(
              response.data.data === null ? [] : response.data.data.allocations
            )
          );
        }
      });
    } catch (e) {
      console.log(e);
      if (e.response.status === 401) {
        handleNotice({
          // Expired Session
          statuscode: 3,
          message: e.response.data.message,
          type: 3,
        });
      } else {
        handleNotice({
          statuscode: 2,
          message: e.message,
          type: 2,
        });
      }
    }
  };

  const FetchDirectors = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/directors`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        // console.log(response.data.data);
        if (response.data.success === true) {
          Store.dispatch(getAllDirectors(response.data.data));
        }
      });
    } catch (e) {
      // console.log(e.response.data.message)
    }
  };

  const FetchamountDue = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/finance/home`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          Store.dispatch(financialData(response.data.data));
          setLoading(false);
        }
      });
    } catch (e) {
      // setLoading(false);
    }
  };

  const Makeseerbitcall = async () => {
    const seerbitObj = SeerbitDataID.filter(
      (filteredobj) => filteredobj.region === "ML"
    );

    try {
      var config = {
        method: "GET",
        url: `${process.env.REACT_APP_SEERBIT}/${seerbitObj[0].pocketId}`,
        headers: {
          "Public-Key": "SBPUBK_ZMKTO3FAN2RAODYB37RD6XRPBJGPPTWE",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).bearerToken,
        },
      };

      await axios(config).then((response) => {
        if (response.data.message === "Success") {
          Store.dispatch(pocketDetails(response.data.data));
        }
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const FetchPointBreakdown = async () => {
    try {
      var config = {
        method: "GET",
        url: `${NodeServer_API}/finance/points_breakdown`,
        headers: {
          Authorization: "Bearer " + cookies.Token,
        },
      };

      await axios(config).then((response) => {
        if (response.data.success === true) {
          console.log(response.data.data);
          Store.dispatch(pointBreakdown(response.data.data));
        }
      });
    } catch (e) {
      // setLoading(false);
    }
  };

  const menubarClick = () => {
    setMenu(!menu);
  };

  const TreatedClicks = (incoming) => {
    Store.dispatch(
      toggleStatus({
        id: 0,
        status: 0,
        page: "",
      })
    );

    SetTreatemail(incoming);
  };

  const handleCloseoverlay = () => {
    setAddDirector(false);
    setCanlogin(false);
    setAddRegion(false);
    setAddProm(false);
  };

  const handleAddDir = () => {
    setAddDirector(true);
  };

  const handleAddReg = () => {
    setAddRegion(true);
  };

  const handleAddProm = () => {
    setAddProm(true);
  };

  const handlExpiration = () => {
    setExpiration(true);
  };

  const handleNameSearch = (incoming) => {
    setAgencyName(incoming);
  };

  const handleClose = () => {
    setSuccessful({
      statuscode: 0,
      message: "",
      type: "",
    });
  };

  const handleSetCountry = () => {};

  const handleNotice = (props) => {
    setSuccessful({ successful, ...props });
  };
  // Store.dispatch(captureTopPerforming(false));

  return (
    <>
      {loading ? (
        <SLoader type="bars" text="Fetching Data" />
      ) : (
        <div className="bnwd-container">
          {isLoading.status && (
            <div className="flex-1 w-full fixed top-0 h-screen items-center justify-center flex z-30 bg-[#0000006d]">
              <Loader type={isLoading.type} text={isLoading.text} />
            </div>
          )}

          <TopSidebar menuClicked={menubarClick} />
          <div className="bnw-botm-container">
            <Sidebar changeWidth={menu} />
            <div className="flex flex-1 overflow-x-auto">
              {page === "Main" ? (
                <Body regioncode={adminregion} />
              ) : page === "Claims" ? (
                <Claims setloading={handleNotice} regioncode={adminregion} />
              ) : page === "Claim" ? (
                <Claim setloading={handleNotice} />
              ) : page === "Budget" ? (
                <Budget
                  regioncode={adminregion}
                  closeOverlay={handleCloseoverlay}
                />
              ) : page === "Wallet" ? (
                <Wallet
                  setloading={handleNotice}
                  closeOverlay={handleCloseoverlay}
                  setadd={handleAddReg}
                  setExpire={handlExpiration}
                  nameSearch={agencyName}
                />
              ) : page === "Region" ? (
                <Regions
                  setloading={handleNotice}
                  closeOverlay={handleCloseoverlay}
                  setadd={handleAddReg}
                  setExpire={handlExpiration}
                  nameSearch={agencyName}
                />
              ) : page === "Promo" ? (
                <Promo setadd={handleAddProm} setExpire={handlExpiration} />
              ) : page === "exrate" ? (
                <Exchangerate
                  setloading={handleNotice}
                  closeOverlay={handleCloseoverlay}
                  setcountry={handleSetCountry}
                />
              ) : page === "Directors" ? (
                <Director
                  setloading={handleNotice}
                  setadd={handleAddDir}
                  nameSearch={agencyName}
                />
              ) : page === "Transactions" ? (
                <Transaction
                  setloading={handleNotice}
                  regioncode={adminregion}
                />
              ) : page === "Subpockets" ? (
                <Subpockets
                  setloading={handleNotice}
                  regioncode={adminregion}
                />
              ) : page === "Agencies" ? (
                <Agencies
                  setreat={TreatedClicks}
                  setloading={handleNotice}
                  nameSearch={agencyName}
                />
              ) : page === "Settings" ? (
                <Settings setloading={handleNotice} />
              ) : (
                <Loginpg />
              )}
            </div>
          </div>

          {successful.statuscode === 1 ? (
            <LoadVeriy type="spin" text={successful.message} />
          ) : successful.statuscode === 2 ? (
            <Error
              closeOverlay={handleClose}
              message={successful.message}
              isTimeout={false}
              icon={successful.type}
            />
          ) : successful.statuscode === 3 ? (
            <Error
              message={successful.message}
              isTimeout={true}
              icon={successful.type}
            />
          ) : (
            <></>
          )}

          {addDirector && (
            <AddDirectors
              closeOverlay={handleCloseoverlay}
              setExpire={handlExpiration}
            />
          )}
          {addRegion && <AddRegions closeOverlay={handleCloseoverlay} />}
          {addProm && <AddPromo closeOverlay={handleCloseoverlay} />}
          {treatConfirm && (
            <Confirmpg
              setloading={handleNotice}
              regioncode={adminregion}
              // closeOverlay={handleCloseoverlay}
              // claimsparams={treats}
            />
          )}
          {toggleStatus?.page !== "" && <Confirms />}
          {expiration && (
            <Error
              message={"Session Expired Re-Login"}
              isTimeout={true}
              icon="3"
            />
          )}
          {notice === "newallocation" ? (
            <Allocate setloading={handleNotice} />
          ) : notice === "newbudget" ? (
            <Budgetfinance setloading={handleNotice} />
          ) : notice === "newpocket" ? (
            <Pockets setloading={handleNotice} />
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default Dashboard;
